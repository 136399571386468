.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message-bubble-left {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
}

.message-bubble-right {
  background-color: #4affb4;
  padding: 5px 10px;
  border-radius: 10px;
}


.p-chat {
  padding-bottom: 0;
  margin-bottom: 0;
}

.p-jam {
  font-size: 12px;
  text-align:end;
  display: block;
  color: gray;
}

.chat-not-found-screen {
  /* height: calc('100%' - 100px); */
  height: '100vh';
  justify-content: 'center';
  align-items: 'center';
}

.pointer {
  cursor: pointer;
}

.list-group-item.pointer:hover {
  cursor: pointer;
  color: #f0f0f0;
  background-color: coral;
  font-weight: bold;
}

.active-tab {
  color: coral;
}

.image-container-responsive {
  max-width: 100%;
  height: auto;
}

.image-container-responsive img {
  max-width: 100%;
  height: auto;
}
